import React from "react";
import { Footer, Navbar } from "../components";

const PricingPage = () => {
  return (
    <>
      <Navbar />
      <div style={styles.container}>
        {/* Заголовок страницы */}
        <h1 style={styles.heading}>Pricing</h1>

        {/* Блок с продуктами */}
        <section style={styles.section}>
          <h2 style={styles.sectionHeading}>Products</h2>
          <p style={styles.sectionDescription}>
            Discover our all-in-one solutions for travel and expense management.
          </p>
          <div style={styles.grid}>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>All-In-One</h5>
              <p style={styles.cardText}>
                Expense, travel, and card reconciliation to control spend and
                unify reporting.
              </p>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Card Management</h5>
              <p style={styles.cardText}>
                Reconcile your transactions and create virtual cards instantly.
              </p>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Expense Tracking</h5>
              <p style={styles.cardText}>
                Streamline expenses and improve visibility into business spend.
              </p>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Premium Insights</h5>
              <p style={styles.cardText}>
                Customized reports and data export to monitor spend and
                compliance.
              </p>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Travel Management</h5>
              <p style={styles.cardText}>
                Book flights, hotels, and cars with custom budgets and
                approvals.
              </p>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Integrations</h5>
              <p style={styles.cardText}>
                Sync your current software for a seamless experience.
              </p>
            </div>
          </div>
        </section>

        {/* Блок с решениями */}
        <section style={styles.section}>
          <h2 style={styles.sectionHeading}>Solutions</h2>
          <p style={styles.sectionDescription}>
            Tailored solutions for every business user, industry, and role.
          </p>
          <div style={styles.grid}>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>By Roles</h5>
              <p style={styles.cardText}>
                Solutions for Finance Teams, Travel Managers, and more.
              </p>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>By Industry</h5>
              <p style={styles.cardText}>
                Designed for industries like Food & Beverage, Healthcare, and
                Tech.
              </p>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Customer Stories</h5>
              <p style={styles.cardText}>
                Hear from companies using our platform to power their travel and
                expenses.
              </p>
            </div>
          </div>
        </section>

        {/* Блок с ценами */}
        <section style={styles.section}>
          <h2 style={styles.sectionHeading}>Pricing</h2>
          <p style={styles.sectionDescription}>
            Flexible pricing for your business needs.
          </p>
          <div style={styles.grid}>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Travel</h5>
              <p style={styles.cardText}>$25 User/Month*</p>
              <a href="/contact" style={styles.button}>
                Contact Us
              </a>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Expense</h5>
              <p style={styles.cardText}>$10 User/Month*</p>
              <a href="/contact" style={styles.button}>
                Contact Us
              </a>
            </div>
            <div style={styles.card}>
              <h5 style={styles.cardTitle}>Travel & Expense</h5>
              <p style={styles.cardText}>Custom Pricing</p>
              <a href="/contact" style={styles.button}>
                Contact Us
              </a>
            </div>
          </div>
        </section>

        {/* Блок с дополнительными возможностями */}
        <section style={styles.section}>
          <h2 style={styles.sectionHeading}>Additional Capabilities</h2>
          <div style={styles.grid}>
            <div style={styles.card}>
              <p style={styles.cardText}>
                U.S. Bank Corporate Card with Annual Rebate
              </p>
            </div>
            <div style={styles.card}>
              <p style={styles.cardText}>
                Premium Insights: Built-in Analytics Reporting
              </p>
            </div>
            <div style={styles.card}>
              <p style={styles.cardText}>Group Events & Meeting Travel</p>
            </div>
            <div style={styles.card}>
              <p style={styles.cardText}>Quick Start Implementation Package</p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

// Стили для оформления страницы
const styles = {
  container: {
    padding: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  heading: {
    fontSize: "2.5rem",
    textAlign: "center",
    margin: "20px 0",
    fontWeight: "bold",
  },
  section: {
    marginBottom: "40px",
  },
  sectionHeading: {
    fontSize: "2rem",
    textAlign: "center",
    marginBottom: "20px",
  },
  sectionDescription: {
    textAlign: "center",
    fontSize: "1.2rem",
    marginBottom: "30px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
  },
  card: {
    backgroundColor: "#f9f9f9",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  cardTitle: {
    fontSize: "1.5rem",
    marginBottom: "10px",
    fontWeight: "600",
  },
  cardText: {
    fontSize: "1rem",
    marginBottom: "20px",
    lineHeight: "1.5",
  },
  button: {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "5px",
    fontWeight: "500",
  },
};

export default PricingPage;
