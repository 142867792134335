import React from "react";
import { Link } from "react-router-dom"; // Make sure you have react-router-dom installed

const PartnershipSection = () => {
  return (
    <section className="partnership-section py-5">
      <div className="container text-center">
        <h2>Partnership</h2>
        <p>Cooperate and grow together with RateHawk</p>

        <div className="row mt-4">
          {/* Affiliate Partners */}
          <div className="col-md-3 mb-4">
            <div className="partner-card p-3 border rounded shadow-sm">
              <h5>For Affiliate Partners</h5>
              <p>
                We make it easy for websites and other online services to
                integrate into our reliable system, giving users access to
                bookings at great rates. Still have questions? Contact us at
                affiliate@emergingtravel.com.
              </p>
              <Link to="/contact" className="btn btn-link">
                Learn More
              </Link>
            </div>
          </div>

          {/* Suppliers */}
          <div className="col-md-3 mb-4">
            <div className="partner-card p-3 border rounded shadow-sm">
              <h5>For Suppliers</h5>
              <p>
                We are official partners of the world's largest OTA,
                wholesalers, consolidators, and DMC. If you are interested in
                sales and developing your business, please write to us at
                tpp@emergingtravel.com.
              </p>
              <Link to="/contact" className="btn btn-link">
                Learn More
              </Link>
            </div>
          </div>

          {/* Hotels */}
          <div className="col-md-3 mb-4">
            <div className="partner-card p-3 border rounded shadow-sm">
              <h5>For Hotels</h5>
              <p>
                When you become our partner, we help increase direct sales and
                brand awareness of your accommodation. Register directly or
                contact hotels@emergingtravel.com.
              </p>
              <Link to="/contact" className="btn btn-link">
                Learn More
              </Link>
            </div>
          </div>

          {/* Marketing and PR */}
          <div className="col-md-3 mb-4">
            <div className="partner-card p-3 border rounded shadow-sm">
              <h5>For Marketing and PR</h5>
              <p>
                We constantly run promotions and campaigns to incentivize and
                delight our clients with special offers. We hold lots of events
                with partner integrations. Please send your proposals to us at
                pr@emergingtravel.com.
              </p>
              <Link to="/contact" className="btn btn-link">
                Learn More
              </Link>
            </div>
          </div>

          {/* Technology Providers */}
          <div className="col-md-3 mb-4">
            <div className="partner-card p-3 border rounded shadow-sm">
              <h5>For Technology Providers</h5>
              <p>
                If you're mid-office or a platform looking to integrate with
                RateHawk to grow your business, contact api@ratehawk.com.
              </p>
              <Link to="/contact" className="btn btn-link">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnershipSection;
