import React from "react";
import { Footer, Navbar } from "../components";
import PartnershipSection from "../components/PartnershipSection";
import { Link } from "react-router-dom"; // Make sure you have react-router-dom installed

const HomePage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-5">
        {/* Hero Section */}
        <section className="hero-section text-center py-5">
          <h1>Unrivalled online booking tool for travel professionals</h1>
          <p>
            We lead the way in accommodation: 2.6M+ hotels worldwide from 280+
            suppliers, air tickets, transfers, car rentals, and other services.
          </p>
          <Link to="/contact" className="btn btn-primary">
            Get started for free
          </Link>
        </section>

        {/* Service Highlights */}
        <section className="service-highlights py-5">
          <div className="row text-center">
            <div className="col-md-4">
              <img src="/images/hotels.webp" alt="Hotels" width="100%" />
              <h4>More than 2,600,000+ hotels worldwide</h4>
            </div>
            <div className="col-md-4">
              <img src="/images/api.png" alt="API Integrations" width="100%" />
              <h4>API integrations for your business</h4>
            </div>
            <div className="col-md-4">
              <img src="/images/mobile-app.jpg" alt="Mobile App" width="100%" />
              <h4>Mobile app for any solution</h4>
            </div>
          </div>
        </section>

        {/* Profit Section */}
        <section className="profit-section py-5 bg-light text-center">
          <h2>Get more profit</h2>
          <p>
            Enjoy our profitable loyalty program to earn points and use them to
            pay for bookings. Get special B2B rates and low prices to earn even
            more.
          </p>
        </section>

        {/* API Section */}
        <section className="api-section py-5 text-center">
          <h2>Fast and simple API integration</h2>
          <p>
            Our architectural solution allows you to quickly and easily install
            the functionality best suited for your business.
          </p>
        </section>

        {/* Services Section */}
        <section className="services-section py-5">
          <h2 className="text-center">Our Services</h2>
          <div className="row text-center">
            <div className="col-md-3">
              <img src="/images/accommodation.jpg" alt="Accommodation" />
              <h5>Accommodation</h5>
              <p>Over 2.6M+ options in 220 countries.</p>
            </div>
            <div className="col-md-3">
              <img src="/images/transfers.jpg" alt="Transfers" />
              <h5>Transfers</h5>
              <p>Available in 100+ countries.</p>
            </div>
            <div className="col-md-3">
              <img src="/images/flights.jpg" alt="Flights" />
              <h5>Flights</h5>
              <p>Air tickets from 400 airlines worldwide.</p>
            </div>
            <div className="col-md-3">
              <img src="/images/car-rental.jpg" alt="Car Rental" />
              <h5>Car Rental</h5>
              <p>Book a car for your clients in two minutes.</p>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section className="cta-section text-center py-5">
          <h2>Become a super travel agent</h2>
          <p>
            Quick, one-step registration to get access to the world. No waiting
            time. No fees.
          </p>
          <Link to="/contact" className="btn btn-primary">
            Join NOW
          </Link>
        </section>
      </div>
      <PartnershipSection />
      <Footer />
    </>
  );
};

export default HomePage;
