import React from "react";
import { Footer, Navbar } from "../components";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Contact Us</h1>
        <hr />
        <p className="lead text-center">
          We are always here to help! Please leave your inquiries below, and our
          team will get back to you shortly with all the information you need to
          plan your perfect getaway.
        </p>
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="form-group my-3">
                <label htmlFor="Name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="Name"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="Email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="Email"
                  placeholder="name@example.com"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="Message">Message</label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="Message"
                  placeholder="Enter your message"
                  required
                />
              </div>
              <div className="text-center">
                <button
                  className="my-2 px-4 mx-auto btn btn-dark"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Contact Information */}
        <div className="row text-center mt-5">
          <div className="col-md-12">
            <h2>Contact Information</h2>
            <p>
              If you prefer to reach out to us directly, here are our contact
              details:
            </p>
            <ul className="list-unstyled">
              <li>
                <strong>Address:</strong> 444 Chicago Ridge Mall, Chicago Ridge, IL 60415-2671
              </li>
              <li>
                <strong>Phone:</strong> +1 312-262-4720
              </li>
              <li>
                <strong>Email:</strong>{" "}
                <a href="mailto:info@tboholoda.com">
                  info-centr@tboholoda.com
                </a>
              </li>
            </ul>
            <p>We look forward to hearing from you!</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
