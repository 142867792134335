import React from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">About Us</h1>
        <hr />
        <p className="lead text-center">
          Welcome to tboholoda! We are an innovative B2B travel solutions
          provider, offering cutting-edge API integrations and tailored travel
          experiences for businesses around the globe. With over a decade of
          expertise, we help travel professionals and corporations enhance their
          services through seamless technology and access to a vast range of
          travel products.
        </p>

        {/* Block 1: API Solutions */}
        <section className="my-5">
          <h2 className="text-center py-4">API Solutions</h2>
          <div className="row">
            <div className="col-md-6">
              <img
                src="/images/api-integration.webp"
                alt="API Integration"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div>
                <h5>Seamless Travel API Integration</h5>
                <p>
                  Our easy-to-use API allows travel professionals to access over
                  2.6 million accommodation options, flights, and additional
                  services. With quick integration, you can bring the world of
                  travel to your clients with just a few clicks.
                </p>
                <ul>
                  <li>
                    🌍 Global Inventory: Access to hotels in 220+ countries.
                  </li>
                  <li>⚙️ Fast integration: Setup in less than a day.</li>
                  <li>
                    📞 24/7 technical support and personal account management.
                  </li>
                </ul>

                <Link to="/contact" className="btn btn-primary">
                  Learn More About Our API
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Block 2: Why Choose Us? */}
        <section className="my-5">
          <h2 className="text-center py-4">Why Choose tboholoda?</h2>
          <div className="row">
            <div className="col-md-4 text-center">
              <img
                src="/images/global-network.jpg"
                alt="Global Network"
                className="img-fluid mb-3"
              />
              <h5>Global Network</h5>
              <p>
                We work with over 280 suppliers worldwide, offering competitive
                rates and reliable booking services.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img
                src="/images/customized-solutions.jpg"
                alt="Customized Solutions"
                className="img-fluid mb-3"
              />
              <h5>Customized Solutions</h5>
              <p>
                Tailored travel packages for corporate clients and travel
                agencies, ensuring personalized experiences.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img
                src="/images/24-7-support.jpg"
                alt="24/7 Support"
                className="img-fluid mb-3"
              />
              <h5>24/7 Support</h5>
              <p>
                Dedicated support team ready to assist you with any technical or
                travel-related queries at any time.
              </p>
            </div>
          </div>
        </section>

        {/* Block 3: Our Products */}
        <section className="my-5">
          <h2 className="text-center py-4">Our Products</h2>
          <div className="row">
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card h-100">
                <img
                  className="card-img-top img-fluid"
                  src="/images/corporate-travel.jpg"
                  alt="Corporate Travel"
                  height={160}
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Corporate Travel</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card h-100">
                <img
                  className="card-img-top img-fluid"
                  src="/images/group-bookings.jpg"
                  alt="Group Bookings"
                  height={160}
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Group Bookings</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card h-100">
                <img
                  className="card-img-top img-fluid"
                  src="/images/luxury-travel.jpg"
                  alt="Luxury Travel"
                  height={160}
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Luxury Travel</h5>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <div className="card h-100">
                <img
                  className="card-img-top img-fluid"
                  src="/images/adventure-tours.jpg"
                  alt="Adventure Tours"
                  height={160}
                />
                <div className="card-body">
                  <h5 className="card-title text-center">Adventure Tours</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Block 4: Achievements */}
        <section className="my-5">
          <h2 className="text-center py-4">Our Achievements</h2>
          <div className="row">
            <div className="col-md-6">
              <p className="text-center">
                We are proud to have been a key partner for many corporate
                clients across the world, providing travel services in over 220
                countries. Our technology empowers businesses to manage their
                travel needs seamlessly.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/images/our-achievements.jpg"
                alt="Our Achievements"
                className="img-fluid"
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
