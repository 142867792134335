import React from "react";
import { Link } from "react-router-dom";

const APISolutions = () => {
  return (
    <div className="api-solutions">
      <header className="text-center my-5">
        <h1 className="fw-bold">Develop Your Travel Product with Our API</h1>
        <p className="lead">
          Use the vast inventory of RateHawk with fast and cutting-edge API
          integration.
        </p>
        <Link to="/register" className="btn btn-primary btn-lg">
          Register
        </Link>
      </header>

      <section className="integration-info text-center my-5">
        <h2 className="fw-bold">Integration within a day</h2>
        <p>Easy and restful API integration</p>
        <p>Technical support and personal manager to help with integration.</p>
        <p>
          The whole world in a single integration: Wide range of inventory with
          instant confirmation through direct and supplier contracts with
          thousands of resorts, apartments, and the world's largest hotel
          chains.
        </p>
      </section>

      <div className="container my-5">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm">
              <img
                src="/images/image-1.jpg"
                alt="280 suppliers"
                className="card-img-top"
              />
              <div className="card-body">
                <h5 className="card-title">280+ Suppliers</h5>
                <p className="card-text">
                  Get all available rates. Our unique technology provides
                  numerous options to ensure you get the best deals.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm">
              <img
                src="/images/image-2.jpg"
                alt="2.6M accommodations"
                className="card-img-top"
              />
              <div className="card-body">
                <h5 className="card-title">2.6M+ Accommodation Options</h5>
                <p className="card-text">
                  Access all accommodations: city hotels, luxury resorts, guest
                  houses, apartments, and campgrounds.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm">
              <img
                src="/images/image-3.jpg"
                alt="220 countries"
                className="card-img-top"
              />
              <div className="card-body">
                <h5 className="card-title">220 Countries and Territories</h5>
                <p className="card-text">
                  To travel anywhere your client may need: for vacation,
                  business, or work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="api-advantages text-center my-5">
        <h2 className="fw-bold">
          Leverage the Full Advantages of Our API Solution
        </h2>
        <p>
          Easy API integrations that provide customized and rapid integration
          via hi-tech platforms.
        </p>
        <p>
          Easy-to-use rich hotel content with complete multilingual information
          on over 2,600,000 hotels.
        </p>
        <p>
          Fast response times under high load. Your business can't wait a
          minute. Fast work and high reliability with RateHawk solutions.
        </p>
        <Link to="/documentation" className="btn btn-info">
          Read the Documentation
        </Link>
      </section>

      <section className="integration-methods text-center my-5">
        <h2 className="fw-bold">Integration Methods</h2>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="card shadow-sm">
              <img
                src="/images/image-4.jpg"
                alt="Direct Connectivity"
                className="card-img-top"
                width="100px"
              />
              <div className="card-body">
                <h5 className="card-title">Direct Connectivity</h5>
                <p className="card-text">
                  Take inventory directly from RateHawk.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="card shadow-sm">
              <img
                src="/images/image-5.jpg"
                alt="Non-direct Connectivity"
                className="card-img-top"
              />
              <div className="card-body">
                <h5 className="card-title">Non-direct Connectivity</h5>
                <p className="card-text">
                  Connect through one of many technological partners for fast
                  and easy API integration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="getting-started text-center my-5">
        <h2 className="fw-bold">Start Using the API in a Few Simple Steps</h2>
        <ol className="list-unstyled">
          <li>01 - Complete Registration</li>
          <li>02 - Get Your Free API Key</li>
          <li>03 - Pass the Easy Certification Process</li>
          <li>04 - Explore All the Advantages of Our API Integration</li>
        </ol>
      </section>

      <section className="support text-center my-5">
        <h2 className="fw-bold">We Are Here for You</h2>
        <p>
          Technical support service is constantly in touch with our partners.
          <br />
          50+ languages, 24/7 support service if you need help with bookings.
        </p>
        <p>Personal account manager ready to help with any questions.</p>
        <p>
          Pre-check service to ensure that the hotel is expecting your guests.
        </p>
      </section>

      <footer className="text-center my-5">
        <h2 className="fw-bold">
          Gain the Most from Fast Engine and Rich Inventory
        </h2>
        <Link to="/contact" className="btn btn-primary">
          Get started for free
        </Link>
      </footer>
    </div>
  );
};

export default APISolutions;
