import React from "react";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import Advantages from "../components/Advantages";

const Login = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Login to Your Travel Account</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="my-3">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                  required
                />
              </div>
              <div className="my-3">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  required
                />
              </div>
              <div className="my-3">
                <p>
                  New Here?{" "}
                  <Link
                    to="/register"
                    className="text-decoration-underline text-info"
                  >
                    Register
                  </Link>
                </p>
              </div>
              <div className="text-center">
                <button className="my-2 mx-auto btn btn-dark" type="submit">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="text-center mt-4">
          <p>
            At tboholoda, we believe in making every journey extraordinary.
            Whether you're planning a relaxing getaway or an adventurous
            expedition, our team is here to help you every step of the way. Log
            in to explore exclusive deals and personalized travel experiences!
          </p>
        </div>
      </div>
      <Advantages />
      <Footer />
    </>
  );
};

export default Login;
