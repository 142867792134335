import React from "react";

const Footer = () => {
  return (
    <footer className="bg-secondary text-white text-center py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold">About Us</h5>
            <p>
              tboholoda is dedicated to providing exceptional travel
              experiences tailored to your needs.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/" className="text-decoration-none text-white">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="text-decoration-none text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="/product" className="text-decoration-none text-white">
                  API Solutions
                </a>
              </li>
              <li>
                <a href="/contact" className="text-decoration-none text-white">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold">Connect with Us</h5>
            <div>
              <a
                className="text-white fs-3 me-3"
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                className="text-white fs-3"
                href="https://facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-4 bg-white" />
        <div className="d-flex align-items-center justify-content-center">
          <p className="mb-0">
            All rights reserved. Made by DesignASAPStudioBRB
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
