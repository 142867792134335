import React from "react";
import { Footer, Navbar, Product } from "../components";
import APISolutions from "../components/APISolutions";

const Products = () => {
  return (
    <>
      <Navbar />
      <APISolutions />
      <Footer />
    </>
  );
};

export default Products;
